import config from "../../config";


/**
 * Create an alert element
 * 
 * @param {string} content
 * @param {string} type
 * @return {HTMLDivElement}
 */
function makeAlert(content, type = "success") {
    const alertContainer = document.createElement("div");
    alertContainer.classList.add("alert", "alert-dismissible", "alert-" + type);
    alertContainer.role = "alert";

    const contentContainer = document.createElement("div");
    contentContainer.textContent = content;

    const closeButton = document.createElement("button");
    closeButton.classList.add("btn-close");
    closeButton.type = "button";
    closeButton.ariaLabel = "Закрыть";
    closeButton.setAttribute("data-bs-dismiss", "alert");

    alertContainer.appendChild(contentContainer);
    alertContainer.appendChild(closeButton);

    return alertContainer;
}

/**
 * Fetch JSON representation of a content unit
 * 
 * @param {string} itemName
 * @return {Promise<object>}
 */
function fetchContent(itemName) {
    return fetch(config.content_urls[itemName])
        .then((res) => {
            if (!res.ok) {
                throw new Error(res.status)
            }

            return res.json();
        });
}

/**
 * @param {object} data
 * @return {Promise<object>}
 */
function fetchNews(data) {
    return fetch(config.api_host + "/news.php?" + new URLSearchParams(data).toString())
        .then((res) => {
            if (!res.ok) {
                throw new Error(res.status)
            }

            return res.json();
        });
}

/**
 * Convert Date to string "day.month.year"
 * @param {Date} date 
 * @return {string}
 */
function dateToString(date) {
    const russianDateFormatter = new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });

    return russianDateFormatter.format(date);
}

/**
 * @param {string} url
 * @return {string}
 */
function constructHref(url) {
    return (process.env.PUBLIC_URL || "") + url
}


export { makeAlert, fetchContent, fetchNews, dateToString, constructHref };