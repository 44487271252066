const API_HOST = "https://xn----7sbbaof2a0ad6bzbx.xn--p1ai/content-api"
const DATA_HOST = process.env.NODE_ENV == "development" ? "http://localhost:3000" : process.env.PUBLIC_URL + "/data"

const config = {
    "data_host": DATA_HOST,
    "api_host": API_HOST,
    "content_urls": {
        // у этих блоков сложная структура
        "index": DATA_HOST + "/content/index.json",
        "globals": DATA_HOST + "/content/globals.json",
        "details": DATA_HOST + "/content/details.json",

        "whats-it": API_HOST + "/whats-it.php",
        "important": API_HOST + "/important.php",
        "dfa-pros-for-issuers": API_HOST + "/text-pages.php?id=28",
        "pros": API_HOST + "/pros.php",
        "dfa-applies": API_HOST + "/text-pages.php?id=30",
        "how-to-issue": API_HOST + "/text-pages.php?id=31",
        "dfa-pros-for-investors": API_HOST + "/text-pages.php?id=32",
        "how-to-become-investor": API_HOST + "/text-pages.php?id=33",
        "how-to-start-investing": API_HOST + "/text-pages.php?id=34",
        "for-banks": API_HOST + "/text-pages.php?id=35",
        
        "for-exchange-operators": API_HOST + "/text-pages.php?id=49",				
        "for-agents": API_HOST + "/text-pages.php?id=50",
        "faq": API_HOST + "/faq.php",
        "start": API_HOST + "/text-pages.php?id=55"
    }
}

export default config;
