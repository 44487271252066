import { fetchContent, constructHref } from "./helpers.js";

fetchContent("globals").then((data) => {
    const navbar = document.getElementById("navbar-items");

    for (const [label, address] of Object.entries(data.navbar).reverse()) {
        const item = document.createElement("li");
        const link = document.createElement("a");
        if (process.env.NODE_ENV === "production" && address.startsWith('/#')) {
            link.href = address
        } else {
            link.href = constructHref(address)
        }
        link.classList.add("nav-link");

        link.appendChild(document.createTextNode(label));
        item.appendChild(link);

        item.classList.add("nav-item");

        navbar.insertBefore(item, navbar.firstChild);
    }
    if (document.getElementsByTagName("footer").length != 0) {
        const contactList = document.getElementById("contact-list");
        const addressLabel = document.getElementById("phys-address-label");
        for (const [label, address] of Object.entries(data.footer.contacts)) {
            const link = document.createElement("a");
            link.classList.add("d-block", "text-light");
            link.textContent = label;
            link.href = address;

            contactList.appendChild(link);
        }
        addressLabel.innerHTML = data.footer.address;
    }
});